<template>
    <section class="intima-feature-2">
        <div class="container py-5">
            <div class="d-none d-lg-flex row mt-2 mb-4"
                 data-aos="fade-up"
                 data-aos-duration="1000">
                <div class="col-md">
                    <h1 class="text-center display-4 text-md-right font-weight-bolder">女人要錫自己</h1>
                </div>
                <div class="col-md">
                    <h1 class="text-center display-4 text-md-left font-weight-bolder">更要選擇最好</h1>
                </div>
            </div>

            <div class="d-flex d-lg-none row mt-2 mb-4"
                 data-aos="fade-up"
                 data-aos-duration="1000">
                <div class="col-md">
                    <h1 class="text-center text-md-right font-weight-bolder">女人要錫自己</h1>
                </div>
                <div class="col-md">
                    <h1 class="text-center text-md-left font-weight-bolder">更要選擇最好</h1>
                </div>
            </div>

            <h5 class="text-center title mb-4"
                data-aos="fade"
                data-aos-duration="1000">
                ULTRA FEMME 360™ 私密超頻收陰機<br>
                由英國頂尖醫療儀器生產商BTL研發
            </h5>

            <div class="row mb-3"
                 data-aos="fade-up"
                 data-aos-duration="1000">
                <div class="col-md-6 text-md-right h5">
                    ・無痛無創 獲美國FDA及歐盟CE認證
                </div>
                <div class="col-md-6 h5">
                    ・無復原期 不影響活動
                </div>
                <div class="col-md-6 text-md-right h5">
                    ・即棄探頭 安全衛生
                </div>
                <div class="col-md-6 h5">
                    ・療程後膠原仍不斷增生
                </div>
            </div>

            <h5 class="text-center font-weight-bold mb-3"
                data-aos="fade-up"
                data-aos-duration="1000">
                歐洲醫學研究証實ULTRA FEMME 360™ 私密超頻收陰機，用家反映: *
            </h5>

            <div class="d-md-none">
                <swiper v-bind:options="swiperOptions">
                    <swiper-slide class="h-auto">
                        <div class="img-wrapper mb-3"
                             data-aos="flip-up"
                             data-aos-duration="1000">
                            <img class="img-fluid rounded-circle w-100"
                                 alt="Intima"
                                 src="../../../assets/campaign/intima/feature2/satisfaction_up.svg">
                        </div>
                        <h5 class="text-center font-weight-bold mb-3"
                            data-aos="fade-up"
                            data-aos-duration="1000">
                            100%<br>
                            性滿足感提升
                        </h5>
                    </swiper-slide>
                    <swiper-slide class="h-auto">
                        <div class="img-wrapper w-100 mb-3"
                             data-aos="flip-up"
                             data-aos-duration="1000">
                            <img class="img-fluid rounded-circle w-100"
                                 alt="Intima"
                                 src="../../../assets/campaign/intima/feature2/painless.svg">
                        </div>
                        <h5 class="text-center font-weight-bold mb-3"
                            data-aos="fade-up"
                            data-aos-duration="1000">
                            90%<br>
                            無痛
                        </h5>
                    </swiper-slide>
                    <swiper-slide class="h-auto">
                        <div class="img-wrapper w-100 mb-3"
                             data-aos="flip-up"
                             data-aos-duration="1000">
                            <img class="img-fluid rounded-circle w-100"
                                 alt="Intima"
                                 src="../../../assets/campaign/intima/feature2/outlook_fix.png">
                        </div>
                        <h5 class="text-center font-weight-bold mb-3"
                            data-aos="fade-up"
                            data-aos-duration="1000">
                            95%<br>
                            外觀改善顯著
                        </h5>
                    </swiper-slide>
                    <swiper-slide class="h-auto">
                        <div class="img-wrapper w-100 mb-3"
                             data-aos="flip-up"
                             data-aos-duration="1000">
                            <img class="img-fluid rounded-circle w-100"
                                 alt="Intima"
                                 src="../../../assets/campaign/intima/feature2/inside_fix.svg">
                        </div>
                        <h5 class="text-center font-weight-bold mb-3"
                            data-aos="fade-up"
                            data-aos-duration="1000">
                            100%<br>
                            改善尿滲
                        </h5>
                    </swiper-slide>
                    <swiper-slide class="h-auto">
                        <div class="img-wrapper w-100 mb-3"
                             data-aos="flip-up"
                             data-aos-duration="1000">
                            <img class="img-fluid rounded-circle w-100"
                                 alt="Intima"
                                 src="../../../assets/campaign/intima/feature2/cell_reborn.svg">
                        </div>
                        <h5 class="text-center font-weight-bold mb-3"
                            data-aos="fade-up"
                            data-aos-duration="1000">
                            17%<br>
                            膠原細胞重生
                        </h5>
                    </swiper-slide>
                    <div class="swiper-scrollbar" slot="scrollbar"></div>
                </swiper>
            </div>

            <div class="d-none d-md-flex row justify-content-center position-relative">
                <div class="col-md-4 col-lg mb-3">
                    <div class="img-wrapper mb-3"
                         data-aos="flip-up"
                         data-aos-duration="1000">
                        <img class="img-fluid rounded-circle w-100"
                             alt="Intima"
                             src="../../../assets/campaign/intima/feature2/satisfaction_up.svg">
                    </div>
                    <h4 class="text-center font-weight-bold mb-3"
                        data-aos="fade-up"
                        data-aos-duration="1000">
                        100%<br>
                        滿足感提升
                    </h4>
                </div>

                <div class="col-md-4 col-lg mb-3">
                    <div class="img-wrapper w-100 mb-3"
                         data-aos="flip-up"
                         data-aos-duration="1000">
                        <img class="img-fluid rounded-circle w-100"
                             alt="Intima"
                             src="../../../assets/campaign/intima/feature2/painless.svg">
                    </div>
                    <h4 class="text-center font-weight-bold mb-3"
                        data-aos="fade-up"
                        data-aos-duration="1000">
                        90%<br>
                        無痛
                    </h4>
                </div>

                <div class="col-md-4 col-lg mb-3">
                    <div class="img-wrapper w-100 mb-3"
                         data-aos="flip-up"
                         data-aos-duration="1000">
                        <img class="img-fluid rounded-circle w-100"
                             alt="Intima"
                             src="../../../assets/campaign/intima/feature2/outlook_fix.png">
                    </div>
                    <h4 class="text-center font-weight-bold mb-3"
                        data-aos="fade-up"
                        data-aos-duration="1000">
                        95%<br>
                        外觀改善顯著
                    </h4>
                </div>

                <div class="col-md-4 col-lg mb-3">
                    <div class="img-wrapper w-100 mb-3"
                         data-aos="flip-up"
                         data-aos-duration="1000">
                        <img class="img-fluid rounded-circle w-100"
                             alt="Intima"
                             src="../../../assets/campaign/intima/feature2/inside_fix.svg">
                    </div>
                    <h4 class="text-center font-weight-bold mb-3"
                        data-aos="fade-up"
                        data-aos-duration="1000">
                        100%<br>
                        改善尿滲
                    </h4>
                </div>
                <div class="col-md-4 col-lg mb-3">
                    <div class="img-wrapper w-100 mb-3"
                         data-aos="flip-up"
                         data-aos-duration="1000">
                        <img class="img-fluid rounded-circle w-100"
                             alt="Intima"
                             src="../../../assets/campaign/intima/feature2/cell_reborn.svg">
                    </div>
                    <h4 class="text-center font-weight-bold mb-3"
                        data-aos="fade-up"
                        data-aos-duration="1000">
                        17%<br>
                        膠原細胞重生
                    </h4>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import "swiper/css/swiper.min.css";
import {
    Swiper,
    SwiperSlide,
} from "vue-awesome-swiper";

export default {
    name: "IntimaFeature2",
    components: {
        Swiper,
        SwiperSlide,
    },
    data () {
        return {
            "swiperOptions": {
                "loop": false,
                "direction": "horizontal",
                "effect": "slide",
                "slidesPerView": 3,
                "spaceBetween": 25,
                "scrollbar": {
                    "el": '.swiper-scrollbar',
                    "draggable": true,
                },
            },
        }
    },
    computed: {

    },
}
</script>

<style lang="scss" scoped>
@import "./variables.scss";

.intima-feature-2 {
    background-color: $background-color-alt;
    color: $text-color;
    background-image: url($background-alt);

    .img-wrapper {
        & > .img-fluid {
            max-width: 225px;
            border: solid 7.5px $text-color;
        }
    }
}
</style>

<style lang="scss">
.intima-feature-2 {
    .swiper-scrollbar {
        .swiper-scrollbar-drag {
            background: rgba(255, 255, 255, 1) !important;
        }
    }
}
</style>
